import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMessageState } from "./messageSlice";
import { ITicketType } from "../../types/events";
import { IEventCouponCode } from "../../types/couponCodes";
import { AxiosError } from "axios";
import { disableCouponById, getEventCoupons } from "../../api/couponsRequests";
import { setMessage } from "./messageSlice";
import { DiscountCodesStatus } from "../../constants/discountCodes";

export interface ICouponsTicketType extends ITicketType {
  checked: boolean;
}

interface IUpdateTicketTypePayload {
  id: number;
  state: boolean;
}

interface ICouponsState {
  ticketTypes: ICouponsTicketType[];
  couponCodes: IEventCouponCode[];
}

const initialState: ICouponsState = {
  ticketTypes: [],
  couponCodes: [],
};

export const getEventCouponCodes = createAsyncThunk<
  IEventCouponCode[],
  number,
  { rejectValue: AxiosError }
>("coupons/getEventCouponCodes", async (eventId, { rejectWithValue }) => {
  try {
    const { data } = await getEventCoupons(eventId);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    return rejectWithValue(err);
  }
});

export const disableEventCouponCode = createAsyncThunk<number, number, { rejectValue: AxiosError }>(
  "coupons/disableEventCouponCode",
  async (couponId, { rejectWithValue, dispatch }) => {
    try {
      await disableCouponById(couponId);
      dispatch(
        setMessage({
          message: "Coupon code disabled successfully",
          type: "success",
        })
      );
      return couponId;
    } catch (error) {
      dispatch(setMessage({ message: "Error disabling coupon code", type: "error" }));
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  }
);

const couponsSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setTicketTypes: (state, { payload }: PayloadAction<ICouponsTicketType[]>) => {
      state.ticketTypes = payload;
    },
    updateTicketTypeStatus: (state, { payload }: PayloadAction<IUpdateTicketTypePayload>) => {
      state.ticketTypes = state.ticketTypes.map((item) =>
        item.id === payload.id ? { ...item, checked: payload.state } : item
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEventCouponCodes.fulfilled, (state, { payload }) => {
      state.couponCodes = payload;
    });
    builder.addCase(disableEventCouponCode.fulfilled, (state, { payload }) => {
      const couponCodeIndex = state.couponCodes.findIndex((item) => item.id === payload);
      const disabledCouponCode = {
        ...state.couponCodes[couponCodeIndex],
        status: DiscountCodesStatus.INACTIVE,
      };
      if (couponCodeIndex !== -1) {
        state.couponCodes[couponCodeIndex] = disabledCouponCode;
      }
    });
  },
});

const { reducer, actions } = couponsSlice;
export const { setTicketTypes, updateTicketTypeStatus } = actions;
export const couponsSelector = (state: { couponsStore: IMessageState }) => state.couponsStore;
export default reducer;
