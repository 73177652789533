import { Navigate } from "react-router-dom"
import { OrganizationRole } from "../constants/organizationRoles"

// @mui icons
import Icon from "@mui/material/Icon"

// Images
import { lazy } from "react"

const SubscribersList = lazy(() => import("../pages/SubscribersList"));
const OrdersList = lazy(() => import("../pages/OrdersList"));
const Analytics = lazy(() => import("../pages/Analytics"));
const TicketScanner = lazy(() => import("pages/TicketScanner"));
const AllEvents = lazy(() => import("pages/AllEvents"));
const CreateEvent = lazy(() => import("pages/CreateEvent"));
const EditEvent = lazy(() => import("pages/EditEvent"));
const OrderDetails = lazy(() => import("pages/OrderDetails"));
const DiscountCodeDetails = lazy(() => import("pages/DiscountCodeDetailed"));
const TeamManagement = lazy(() => import("pages/TeamManagement/TeamManagement"));
const Settings = lazy(() => import("pages/Settings"));

const routes = [
  {
    type: "redirect",
    name: "Organizer Events",
    route: "*",
    component: <Navigate key="redirect" to="/organizer/events" />,
    roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
  },
  // {
  //   type: "redirect",
  //   name: "Operator redirect",
  //   route: "*",
  //   component: <Navigate key="redirect" to="scanner" />,
  //   roles: [OrganizationRole.OPERATOR]
  // },
  {
    type: "collapse",
    name: "Profile",
    key: "user-account",
    noCollapse: true,
    roles: [OrganizationRole.ADMIN]
  },
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Organizer", key: "title-org", roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR] },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    route: "/organizer/events",
    icon: <Icon fontSize="medium">stadium</Icon>,
    component: <AllEvents />,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN, OrganizationRole.OPERATOR]
  },
  {
    type: "collapse",
    name: "Members",
    key: "team-management",
    icon: <Icon fontSize="medium">people</Icon>,
    route: "/team-management",
    component: <TeamManagement />,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN]
  },
  {
    type: "collapse",
    name: "Insights",
    key: "analytics",
    route: "/organizer/analytics",
    icon: <Icon fontSize="medium">insights</Icon>,
    disabled: true,
    component: <Analytics />,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN]
  },
  { type: "title", title: "MANAGEMENT TOOLS", key: "title-pages", roles: [ OrganizationRole.ADMIN] },
  // {
  //   type: "collapse",
  //   name: "Subscribers",
  //   key: "subscribers",
  //   icon: <Icon fontSize="medium">email</Icon>,
  //   route: "/subscribers",
  //   component: <SubscribersList />,
  //   noCollapse: true,
  //   roles: [OrganizationRole.ADMIN]
  // },
  {
    type: "collapse",
    name: "Ticket Scanner",
    key: "tickets-scanner",
    icon: <Icon fontSize="medium">qr_code_scanner</Icon>,
    route: "/scanner",
    component: <TicketScanner />,
    noCollapse: true,
    roles: [OrganizationRole.ADMIN]
  },
  {
    type: "inherit",
    name: "Event",
    key: "event",
    route: "/organizer",
    roles: [OrganizationRole.ADMIN],
    inheritances: [
      {
        key: "create-event",
        route: "create",
        component: <CreateEvent />,
      },
      {
        key: "edit-event",
        route: "edit/:id",
        component: <EditEvent />,
      },
    ]
  },
  {
    type: "inherit",
    name: "Orders",
    key: "orders-inherit",
    route: "/orders",
    roles: [OrganizationRole.ADMIN],
    inheritances: [
      {
        key: "orders-list-main",
        isIndex: true,
        component: <OrdersList />
      },
      {
        key: "orders-list",
        route: ":id",
        component: <OrdersList />,
      },
      {
        key: "order-detailed",
        route: "detailed/:id",
        component: <OrderDetails />,
      }
    ]
  },
  {
    type: "inherit",
    name: "Discount Codes",
    key: "discount-codes",
    route: "/discount-codes",
    roles: [OrganizationRole.ADMIN],
    inheritances: [
      {
        key: "discount-codes-create",
        route: "mode",
        component: <DiscountCodeDetails />,
      },
      {
        key: "discount-codes-edit",
        route: "mode/:id",
        component: <DiscountCodeDetails />,
      }
    ]
  },
  {
    type: "divider",
    key: "divider-1",
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    noCollapse: true,
    icon: <Icon fontSize="medium">settings</Icon>,
    route: "/settings",
    roles: [OrganizationRole.ADMIN],
    component: <Settings />,
  }
];

export default routes;
