import request from "../utils/request";
import { ICouponCode, IEventCouponCode } from "../types/couponCodes";
import { DiscountCodesStatus } from "../constants/discountCodes";

export const createCoupon = (payload: ICouponCode) => {
  return request({
    url: "/coupons",
    method: "post",
    data: payload,
  });
};

export const getEventCoupons = (eventId: number) => {
  return request({
    url: `/events/${eventId}/coupons`,
    method: "get",
  });
};

export const getCouponById = (couponId: number) => {
  return request({
    url: `/coupons/${couponId}`,
    method: "get",
  });
};

export const editCouponById = (couponId: number, couponDetails: ICouponCode) => {
  return request({
    url: `/coupons/${couponId}`,
    method: "put",
    data: couponDetails,
  });
};

export const disableCouponById = (couponId: number) => {
  return request({
    url: `/coupons/${couponId}/status`,
    method: "put",
    data: {
      id: couponId,
      status: DiscountCodesStatus.INACTIVE,
    },
  });
};
