import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from 'store'
import {fetchSubscribers} from 'api/subscribersRequests'
import {ISubscriber} from 'types/subscribers'

export interface SubscribersState {
  subscribers: ISubscriber[];
  loading: boolean;
  errors: string;
}

const initialState: SubscribersState = {
  subscribers: [],
  loading: false,
  errors: "",
}

export const getSubscribers = (): AppThunk => {
  return  dispatch => {
    dispatch(setLoading(true))
    fetchSubscribers().then((resp) => {
      dispatch(setSubscribers(resp.data))
      dispatch(setLoading(false))
    }).catch((err) => {
      dispatch(setErrors(err.message))
      dispatch(setLoading(false))
    })
  }
}


const subscribersSlice = createSlice({
  name: "subscribers",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setSubscribers: (state, { payload }: PayloadAction<ISubscriber[]>) => {
      state.subscribers = payload
    }
  },
})


const {actions, reducer} = subscribersSlice

export const { setLoading, setErrors, setSubscribers } = actions

export const subscribersSelector = (state: { subscribersStore: SubscribersState }) =>
  state.subscribersStore

export default reducer

