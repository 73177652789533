import React, { useEffect } from "react";
import PublicRoutes from "routes/PublicRoutes";
import App from "./App";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { Notification } from "./components/Notification";
import { clearMessage } from "./features/user/messageSlice";
import { LocalStorageItems } from "./constants/localStorageItems"

const Public = () => {
  const { message, type } = useAppSelector((state) => state.message);
  const userToken = localStorage.getItem(LocalStorageItems.TOKEN);

  const dispatch = useAppDispatch();
  // Display global notification
  useEffect(() => {
    if (message) {
      Notification({
        content: message,
        type,
      });
      dispatch(clearMessage());
    }
  }, [message]);


  return userToken ? <App /> : <PublicRoutes />;
};

export default Public;
