import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from 'config/query-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';


import Public from "./Public";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "scss/index.scss";
import LogRocket from 'logrocket';

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";
LogRocket.init('bjt1g9/unde-dev');
ReactDOM.render(
  <Provider store={store()}>
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <MaterialUIControllerProvider>
        <ToastContainer />
        <QueryClientProvider client={queryClient}>
          <Public />
          <ReactQueryDevtools buttonPosition="bottom-left" />
        </QueryClientProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
