import request from "utils/request";
import { ILoginPayload, IRegisterPayload } from "types/auth";

export const login = (payload: ILoginPayload) => {
  return request({
    url: "/auth",
    method: "post",
    data: payload,
  });
};

export const register = (payload: IRegisterPayload) => {
  return request({
    url: "/signup",
    method: "post",
    data: payload,
  });
};

export const getUserInfo = () => {
  return request({
    url: "/users/principal",
    method: "get",
  });
};
