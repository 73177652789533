import request from "../utils/request";

import { IAboutEvent, IDetails, IEvent, IParticipant } from "../types/events"
import { IGuestListItem, IInvitePayload } from "../types/orders"

export const getEventsByOrganizerId = (id: number) => {
  return request({
    url: `/organizations/${id}/events`,
    method: "get",
  });
};

export const getEventById = (id: number) => {
  return request({
    url: `/events/${id}`,
    method: "get",
  });
};

export const createEvent = (id: number, eventData: IEvent) => {
  return request({
    url: `/organizations/${id}/events`,
    method: "post",
    data: eventData,
  });
};

export const uploadImage = (payload: Blob) => {
  const data = new FormData();
  data.append("image", payload);
  return request({
    url: "/events/image",
    method: "post",
    data,
    headers: { "content-type": "multipart/form-data" },
  });
};

export const updateAboutEvent = (id: number, eventData: IAboutEvent) => {
  return request({
    url: `/events/${id}`,
    method: "put",
    data: eventData,
  });
};

export const updateEventDetails = (id: number, details: IDetails) => {
  return request({
    url: `/events/${id}/detail`,
    method: "put",
    data: details,
  });
};



export const addEventParticipant = (id: number, data: IParticipant[]) => {
  return request({
    url: `/events/${id}/participants`,
    method: "put",
    data,
  });
};

export const removeEventParticipant = (participantId: number) => {
  return request({
    url: `/participants/${participantId}`,
    method: "delete",
  });
};

export const getEventOrders = (id: number, params = {}) => {
  return request({
    url: `/events/${id}/orders`,
    method: "get",
    params,
  });
};

export const getEventOrderById = (id: number) => {
  return request({
    url: `/orders/${id}`,
    method: "get",
  });
};

export const sendInvitation = (id: number, data: IInvitePayload) => {
  return request({
    url: `events/${id}/invitation`,
    method: "post",
    data,
  });
};

export const resendOrderConfirmationEmail = (id: number) => {
  return request({
    url: `orders/${id}/confirmation`,
    method: "get",
  });
};


export const setRegistrationFormFields = (eventId: number, data: object) => {
  return request({
    url: `/events/${eventId}/registrationForms`,
    method: "post",
    data
  })
}

export const getRegistrationFormFields = (eventId: number) => {
  return request({
    url: `/events/${eventId}/registrationForms`,
    method: "get",
  })
}

export const getRegistrationFormAnswers = (eventId: number, registrationFormId: number) => {
  return request({
    url: `/events/${eventId}/registrationForms/${registrationFormId}/responses`,
    method: "get",
    headers: {
      Accept : "text/csv; charset=utf-8",
      "Content-Type": "text/csv; charset=utf-8"
    }
  })
}

export const publishEvent = (eventId: number, published: boolean) => {
  return request({
    url: `/events/${eventId}/publish`,
    method: "put",
    data: {
      published
    }
  })
}


export const uploadInvitationList = (eventId: number, ticketTypeId: number, data: FormData) => {
  return request({
    url: `/events/${eventId}/orders?ticketTypeId=${ticketTypeId}`,
    method: "put",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const sendInvitationsList = (eventId: number, data: IGuestListItem[]) => {
  return request({
    url: `/events/${eventId}/orders`,
    method: "put",
    data,
  })
}
