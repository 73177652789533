import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getEventsByOrganizerId } from "api/eventsRequests";
import { AxiosError } from "axios";
import { IEvent } from "types/events";

export interface EventsState {
  events?: IEvent[];
  selectedEvent?: IEvent;
  loading: boolean;
  errors: string;
}

const initialState: EventsState = {
  events: null,
  selectedEvent: null,
  loading: false,
  errors: "",
};

export const getEvents = createAsyncThunk<IEvent[], number, { rejectValue: AxiosError }>(
  "events/getEvents",
  async (organizerID: number, { rejectWithValue }) => {
    try {
      const { data } = await getEventsByOrganizerId(organizerID);
      return data;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error);
    }
  }
);

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvents.fulfilled, (state, { payload }: PayloadAction<IEvent[]>) => {
      state.events = payload;
      state.loading = false;
    });

    builder.addCase(getEvents.pending, (state) => {
      state.loading = true;
      state.errors = "";
    });
    builder.addCase(getEvents.rejected, (state, { payload }: PayloadAction<AxiosError>) => {
      state.loading = false;
      state.errors = payload.message;
    });
  },
});

const { reducer } = eventsSlice;

export const eventsSelector = (state: { eventsStore: EventsState }) => state.eventsStore;

export default reducer;
