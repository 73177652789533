import { IUser, UserDetails } from "types/userSettings";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getUserInfo } from "../../api/authRquests";
import { IEvent } from "../../types/events"
import {LocalStorageItems} from "../../constants/localStorageItems"

const initialState: IUser = {
  details: JSON.parse(localStorage.getItem(LocalStorageItems.ORGANIZER_DETAILS)),
  controlledEvent: JSON.parse(localStorage.getItem("controlledEvent")),
  error: null,
  loading: false,
};

export const getUserDetails = createAsyncThunk<UserDetails>("user/getUserDetails", async () => {
  try {
    const { data } = await getUserInfo();
    localStorage.setItem(LocalStorageItems.ORGANIZER_DETAILS, JSON.stringify(data));
    return data;
  } catch (err) {
    return err as AxiosError;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails(state, { payload }: PayloadAction<UserDetails>) {
      state.details = payload;
    },
    setControlledEvent(state, { payload }: PayloadAction<IEvent>) {
      state.controlledEvent = payload;
      localStorage.setItem("controlledEvent", JSON.stringify(state.controlledEvent));
    },
    updateControlledEvent(state, { payload }: PayloadAction<Partial<IEvent>>) {
      state.controlledEvent = {
        ...state.controlledEvent,
        ...payload,
      };
      localStorage.setItem("controlledEvent", JSON.stringify(state.controlledEvent));
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.fulfilled, (state, { payload }: PayloadAction<UserDetails>) => {
      state.details = payload;
      state.loading = false;
    });
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUserDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

const { reducer, actions } = userSlice;

export const { setUserDetails, setControlledEvent, updateControlledEvent } = actions;

export const eventsSelector = (state: { userStore: UserDetails }) => state.userStore;

export default reducer;
