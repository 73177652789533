import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"
import { AppThunk } from '../../store'
import { fetchSubscriberById, updateSubscriberById, updateSubscriberNotesById} from 'api/subscribersRequests'
import {ISubscriber, ISubscriberDetails, ISubscriberNote} from 'types/subscribers'
import {AxiosError} from 'axios'


export interface SerializedError {
  name?: string
  message?: string
  stack?: string
  code?: string
}


export interface SubscriberState {
  currentSubscriber?: ISubscriber;
  loading: boolean;
  errors: string;
}

interface UpdateSubscriberNotesPayload {
  payload: ISubscriberNote[];
  id: number;
}

const initialState: SubscriberState = {
  currentSubscriber: null,
  loading: false,
  errors: "",
}



export const getSubscriber = (id:string):AppThunk => {
  return dispatch => {
    dispatch(setLoading(true))
    fetchSubscriberById(id).then((resp) => {
      dispatch(setCurrent(resp.data))
      dispatch(setLoading(false))
    }).catch((err) => {
      dispatch(setLoading(false))
      dispatch(setErrors(err.message))
    })
  }
}


export const updateSubscriberDetails = (id:number, payload:ISubscriberDetails): AppThunk => {
  return dispatch => {
    dispatch(setLoading(true))
    updateSubscriberById(id, payload).then((resp) => {
      dispatch(updateInfo(resp.data))
      dispatch(setLoading(false))
    }).catch((err) => {
      dispatch(setErrors(err.message))
      dispatch(setLoading(false))
    })
  }
}

export const updateSubscriberNotes = createAsyncThunk<ISubscriberNote[], UpdateSubscriberNotesPayload, {rejectValue: AxiosError}>(
  'subscriber/updateSubscriberNotes',
  async ( subData:UpdateSubscriberNotesPayload, {rejectWithValue})  => {
   try {
     const { id, payload } = subData
     const {data} = await updateSubscriberNotesById(id, payload)
     return data
   } catch (err) {
     const error = err as AxiosError
     return rejectWithValue(error)
   }
  }
)


const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setCurrent: (state, {payload}: PayloadAction<ISubscriber> ) => {
      state.currentSubscriber = payload
    },

    updateInfo: (state, {payload}: PayloadAction<ISubscriberDetails>) => {
      state.currentSubscriber = {
        ...state.currentSubscriber,
        ...payload
      }
    },

    updateNotes: (state, {payload}: PayloadAction<ISubscriberNote[]>) => {
      state.currentSubscriber.notes = payload
    }
  },
  extraReducers: (builder => {
    builder.addCase(updateSubscriberNotes.pending,(state) => {
        state.loading = true
        state.errors = ''
    })
    builder.addCase(updateSubscriberNotes.fulfilled,(state, {payload}: PayloadAction<ISubscriberNote[]>) => {
        state.loading = false
        state.currentSubscriber.notes = payload
    })
    builder.addCase(updateSubscriberNotes.rejected,(state, {payload}: PayloadAction<AxiosError>) => {
        state.loading = false
        state.errors = payload.message
    })
  })
})


const {actions, reducer} = subscriberSlice

export const { setLoading, setErrors, setCurrent, updateInfo, updateNotes } = actions

export const subscribersSelector = (state: { subscribersStore: SubscriberState }) =>
  state.subscribersStore

export default reducer
