import {toast} from 'react-toastify'
import {TypeOptions} from 'react-toastify'

interface Props {
  content: JSX.Element | string;
  type?: TypeOptions
}

export const Notification = ({content, type = 'success'}:Props) => {
    return toast(content, {
      type: type,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
}


