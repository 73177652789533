import MDBox from "../../uikit/MDBox";
import { CircularProgress } from "@mui/material";

const Spinner = () => {
  return (
    <MDBox display="flex" justifyContent="center" alignItems="center" height="100%" minHeight="150px">
      <CircularProgress color="secondary" />
    </MDBox>
  );
};
export default Spinner;
