import moment from "moment";
import { ILocation } from "../types/events";
import { OrganizationRole } from "../constants/organizationRoles";
import { parseISO } from "date-fns";

export const formatDate = (value: Date | string | number) => {
  return moment(value).format("DD MMM, HH:mm");
};
export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatLocation = (location: ILocation) => {
  return `${capitalizeFirstLetter(location.country)}, ${capitalizeFirstLetter(location.city)}`;
};

export const getTime = (date: Date | string) => {
  return moment(date).format("HH:mm:ss");
};

export const getDate = (date: Date | string | number) => {
  return moment(date).format("MM-DD-YYYY");
};

export const concatDateTime = (date: Date | string, time: Date | string) => {
  const data = getDate(date) + " " + getTime(time);
  return moment(data).clone().local().format();
};

export const getUnixTimestamp = (date: string | number | Date) => {
  return moment(date).valueOf();
};

export const getISOFromUnix = (date: number) => {
  return moment(date, "x").format();
};

export const getDateFromUnix = (date: number) => {
  return moment(date, "x").format("MM-DD-YYYY HH:mm:ss");
};

export const getDateWithDay = (date: number) => {
  return moment(date, "x").format("LL");
};

export const eventTimeTypeFormatting = (time: Date | number | string) => {
  if (!isNaN(Number(time))) {
    return Number(time);
  } else {
    return getUnixTimestamp(time);
  }
};

export const getDropdownTimeFormat = (time: Date | number | string) => {
  return parseISO(getISOFromUnix(Number(time)));
};

export const getEventTimeRange = (startDate: number, endDate: number) => {
  const startTime = moment(startDate, "x").format("MM/DD HH:mm");
  const endTime = moment(endDate, "x").format("MM/DD HH:mm");

  return `${startTime} - ${endTime}`;
};

export const getDayMonth = (date: string) => {
  return moment(date).format("MMM DD");
};

export const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function mergeArrayObjects<T>(primaryArray: T[], secondaryArray: T[], prop: keyof T) {
  return primaryArray.map((item) => {
    const indexToReplace = secondaryArray.findIndex((element) => element[prop] === item[prop]);
    if (indexToReplace != -1) {
      return secondaryArray[indexToReplace];
    } else {
      return item;
    }
  });
}

export const downloadPdfFile = (data: ArrayBuffer, name: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}.pdf`);
  document.body.appendChild(link);
  link.click();
};

export const getBadgeSettings = (published: boolean) => {
  return {
    color: published ? "success" : "warning",
    badgeContent: published ? "published" : "waiting for approval",
  };
};

export const checkRouteAccess = (allowed: OrganizationRole[], current: OrganizationRole) => {
  return allowed?.includes(current);
};

export const processCSVData = (data: string, fileName: string | number) => {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.style.display = "none";
  link.download = `${fileName}.csv`;
  link.innerHTML = "Click here to download the file";
  document.body.appendChild(link);
  link.click();
};

export const downloadCSVFileByLink = (url: string) => {
  const a = document.createElement("a");
  const fileName = url.split("/").pop();
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};
