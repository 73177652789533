import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeOptions } from "react-toastify";

export interface IMessageState {
  type: TypeOptions;
  message: string;
}

const initialState: IMessageState = {
  type: "default",
  message: "",
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, { payload }: PayloadAction<IMessageState>) => {
      return payload;
    },
    clearMessage: () => {
      return initialState;
    },
  },
});

const { reducer, actions } = messageSlice;
export const { setMessage, clearMessage } = actions;
export const messageSelector = (state: { messageStore: IMessageState }) => state.messageStore;
export default reducer;
