import { ITicketType } from "../types/events"
import request from "../utils/request"

export const addEventTicketType = (id: number, details: ITicketType) => {
  return request({
    url: `/events/${id}/ticketType`,
    method: "post",
    data: details,
  });
};

export const removeEventTicketType = (id: number) => {
  return request({
    url: `/ticketTypes/${id}`,
    method: "delete",
  });
};


export const activateTicket = (uuid: string) => {
  return request({
    url: `/tickets/${uuid}`,
    method: "post",
  });
};

export const getEventTickets = (eventId: number, params = {}, isCSV = false) => {
  return request({
    url: `/events/${eventId}/tickets`,
    method: "get",
    params,
    headers: {
      Accept : isCSV ? "text/csv; charset=utf-8" : "application/json",
    }
  });
};

export const downloadTicket = (uuid: string) => {
  return request({
    url: `/tickets/${uuid}`,
    method: "get",
    responseType: "arraybuffer",
  });
};

export const updateEventTicketType = (eventId: number, ticketTypeId: number, details: ITicketType) => {
  return request({
    url: `/events/${eventId}/ticketType/${ticketTypeId}`,
    method: "put",
    data: details,
  });
}
