import request from '../utils/request'
import {  ISubscriberDetails, ISubscriberNote } from '../types/subscribers'

export const fetchSubscribers = () => {
  return request({
    url: '/events/1/subscribers',
    method: 'get',
  })
}

export const fetchSubscriberById = (id:string) => {
  return request ({
    url: `/subscribers/${id}`,
    method: 'get'
  })
}


export const updateSubscriberById = (id:number, payload: ISubscriberDetails) => {
  return request ({
    url: `/subscribers/${id}`,
    method: 'put',
    data: payload
  })
}

export const updateSubscriberNotesById = (id:number, payload: ISubscriberNote[]) => {
  return request ({
    url: `/subscribers/${id}/notes1`,
    method: 'put',
    data: payload
  })
}
