import { QueryClient } from '@tanstack/react-query';
import { IGetEventOrdersParams } from 'types/orders';

export const QUERY_KEYS = {
  profile: ['profile'],
  teamMembers: (organizationId: number) => ['team-members', organizationId],
  eventOrders: (eventId: number, params: IGetEventOrdersParams) => ['event-orders', eventId, params],
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      retry: false,
    },
  }
});

export default queryClient;
